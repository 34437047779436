const loadTime = performance.now();
const loadTimestamp = new Date().getTime();

const loadLytics = {
    v: 1,
    s: "pageLoad",
    d: {
        lt: Math.round(loadTime),
        lts: Math.round(loadTimestamp),
        wlu: location.href,
        dt: document.title,
        dr: document.referrer,
        na: navigator.userAgent,
        nls: navigator.languages,
        dpr: window.devicePixelRatio,
        wsi: window.innerWidth + "x" + window.innerHeight,
        ws: window.outerWidth + "x" + window.outerHeight,
        wss: window.screen.width + "x" + window.screen.height,
        wsa: window.screen.availWidth + "x" + window.screen.availHeight,
        wsd: window.screen.colorDepth,
    }
};

window.onload = () => {
    fetch(
        new URL("pour", process.env.TEALYTICS_API_URL),
        {
            method: "POST",
            mode: "no-cors",
            body: JSON.stringify(loadLytics),
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        }
    );
}